import axios from "axios"
class NominationApi {
  constructor() {
    this.api = axios.create(
      {
        withCredentials: true,
        timeout: 1000000,

      }
    )
    this.api.defaults.baseURL = process.env.VUE_APP_API_URL

    this.postRequest = function (url, body) {
      return this.api.post(url, body)
    }

    this.getRequest = function (url, params) {
      return this.api.get(url, {
        params: params
      })
    }

  }
  // Login Request
  async login(data) {
    let url = "/Sso/login"
    return this.postRequest(url, data)
  }

  // Get Members In Family
  async advancedFamilyMembers(search, familyId) {
    let url = "/Member/advancedMemberSearch"
    return this.getRequest(url, { searchText: search, searchModule: "award", filter: {skip:0,limit:101,familyId:familyId}, notUsePreLoader: true})
  }
  // Get Award Family
  async getAwardFamily () {
    let url = "/AwardsFamily"
    return this.getRequest(url)
  }

  async getAwardFamilyDetails (familyId) {
    return this.getRequest('/AwardsFamily/details', {
      familyId: familyId
    })
  }
  async getAwardDetails (awardId) {
    return this.getRequest(`/Awards/details`, {
      awardId: awardId
    })
  }
  // Get Awards
  async getAwards (awardId, nominations, awardValue, familyId) {
    let url = `/Awards?familyId=${encodeURIComponent(familyId)}`
    if (awardId > 0) {
      url += `&awardId=${encodeURIComponent(awardId)}`
    }
    nominations.forEach(val => {
      url += `&nominees[]=${encodeURIComponent(val)}`
    })
    
    if (awardValue > 0) {
      url += `&awardValue=${encodeURIComponent(awardValue)}`
    }

    return this.getRequest(url)
  }
  // Delete Uploaded Video
  async deleteUploadedVideo (key) {
    let url = "/Video/delete"
    return this.api.delete(url, {
      data: {
        key: key
      }
    })
  }
  // Upload New video
  async uploadVideo (file, id) {
    let url = "/Video/upload"
    let formData = new FormData()
    formData.append("file", file, file.name)
    return this.api.post(url, formData, {
      withCredentials: true,
      onUploadProgress: (event) => {
        let uploadedData = Math.round((event.loaded / event.total) * 100)
        if (uploadedData > 95) uploadedData = 95
        document.getElementById(id).style = `width:${uploadedData}%`
        document.getElementById(id).innerHTML = `${(event.loaded / (1024 * 1024)).toFixed(2)} / ${(event.total / (1024 * 1024)).toFixed(2)} MB`
      }
    })
  }

  async submitAwardDetails (data) {
    let url = "/Nomination/submit"
    let obj = {
      nomination: data,
      requiresApproval: false
    }
    return this.api.post(url, obj)
  }

  async getAllNomination (page, size) {
    let url = "/Nomination/all"
    return this.getRequest(url, {
      page: page,
      size: size
    })
  }

  async checkBudgetCap (familyId, members, awardId) {
    let url = '/Awards/checkBudgetCaps'
    return this.getRequest(url, {
      familyId: familyId,
      nominees: members,
      awardId: awardId
    })
  }

  async checkNominatorFamilyCap (familyId) {
    let url = '/AwardsFamily/checkNominatorFamilyCap'
    return this.getRequest(url, {
      familyId: familyId
    })
  }

  async checkNomineesFamilyCap (familyId, members) {
    let url = '/Member/checkNomineesFamilyCap'
    return this.getRequest(url, {
      familyId: familyId,
      members: members
    })
  }

  async checkPreventNomination (familyId, members, awardId, awardValue) {
    let url = '/Nomination/checkForPreventNominate'
    return this.postRequest(url, {
      awardId: awardId,
      awardValue: parseInt(awardValue),
      familyId: familyId,
      selectedMembers: members
    })
  }

  async findWithAnswersByAwardFamily (familyId) {
    let url = '/Question/getQuestions'
    return this.getRequest(url, {
      familyId: familyId
    })
  }

  async checkDeterminationAnswers (familyId, nominees, answers) {
    let url = `/Question/checkDeterminationAnswer`
    return this.getRequest(url, {
      familyId: familyId,
      nominees: nominees,
      answers: answers
    })
  }

  async findWithAnswersByAwardSet (familyId, awardId) {
    let url = '/Question/findWithAnswersByAwardSet'
    return this.getRequest(url, {
      familyId: familyId,
      awardId: awardId
    })
  }

  async checkForAwardValueAnswers (familyId, nominees, awardId, answers) {
    console.log(awardId)
    let url = `/Question/checkDeterminationForAwardValue`
    return this.getRequest(url, {
      familyId: familyId,
      nominees: nominees,
      awardId: awardId,
      answers: answers
    })
  }

  async canNominate (familyId) {
    const url = `/Nomination/canNominate`
    try {
      let res = await this.getRequest(url, {
        familyId: familyId
      })
      return [res.data, null]
    } catch (error) {
      return [null, error]
    }
  }
  async checkNotNominatableFromFamily (familyId, members) {
    const url = `Member/checkNotNominatableFromFamily`
    try {
      const res = await this.postRequest(url, {
        familyId: familyId,
        members: members
      })
      return [res.data, null]
    } catch (error) {
      return [null, error]
    }
  }

  async continueSession (expiresTime) {
    const url = 'Sso/continueSession'
    try {
      const res = await this.getRequest(url, {
        cookieExpiresIn: expiresTime
      })
      return [res.data, null]
    } catch (error) {
      return [null, error]
    }
  }
}



export default new NominationApi()